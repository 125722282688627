import React, {
  useState, useLayoutEffect, lazy, useMemo, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SvgIcon from '../../atoms/SvgIcon';
import { useOptions } from '../../../context/options';
import { ImageProp, SVGImageProp } from '../../../models/Section';
import SectionWrapper from '../../common/SectionWrapper';

import devices from '../../../js/styles/Devices';
import style from './style';

const ImageBlock = lazy(() => import('../../blocks/ImageBlock'));

const Title = styled.p`
  margin-bottom: 0.5rem;
  text-align: ${(props) => props.position};
  font-family: var(--font-family);
  font-size: var(--h2-desktop-font-size);
  font-weight: var(--has-font-weight);
  letter-spacing: var(--has-letter-spacing);
  color: ${(props) => props.color};

  @media ${devices.downFromTablet} {
    font-size: var(--h2-mobile-font-size);
    line-break: ${style.lineBreak};
  }
`;

const ExpandButton = styled.button`
  width: 100%;
  margin: ${style.margin};
  padding: ${style.expButPadding};
  display: ${style.displayExpButton};
  flex-direction: ${style.flexDirectExpBut};
  justify-content: ${style.justContExpBut};
  align-items: ${style.alignItExpBut};
  cursor: pointer;
  background-image: none;
  background-color: ${style.buttonBackgroundColor};
  border-color: ${style.borderColor};
  border-style: ${style.borderStyle};
  border-width: ${style.borderWidth};
  border-radius: ${style.borderRadius};
  color: ${style.color};
  font-family: var(--font-family);
  font-size: ${style.expandButtonFont};
  font-weight: bold;
  text-decoration: ${style.textDecExpandBut};
  text-align: center;

  svg {
    margin-right: 0.2rem;
    padding-top: 0.2rem;
    font-size: 1rem;
  }

  @media ${devices.upFromTablet} {
    max-width: 30%;
  }
`;

const TitleLine = styled.div`
  margin: 1.5rem auto;
  width: 7rem;
  height: 3px;
  background-color: var(--sambla-new-red);
  animation: enter 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation-delay: 1s;
`;

const CreditorGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.numberOfCreditors > 6 ? props.creditorsColumnsGrid : `repeat(${props.numberOfCreditors}, 1fr)`)};
  grid-gap: ${style.gridGap};
  padding-bottom: 1rem;
  align-items: center;

  @media ${devices.downFromTablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${devices.mobile} {
    grid-template-columns: ${(props) => props.gridColumnMobile};
  }
`;

const CreditorCard = styled.div`
  min-height: 5rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.backgroundColor};
  aspect-ratio: 3/2;
  object-fit: contain;
`;

const ImageContainer = styled(ImageBlock)`
  width: ${(props) => props.imageWidth};
  margin: auto;
`;

const Subtitle = styled.p`
  max-width: 35rem;
  margin: 0 auto 2rem 0;
  font-size: var(--h4-desktop-font-size);
`;

const Icon = styled(SvgIcon)`
  height: 1rem;
  margin-right: 0.2rem;
  padding-top: 0.2eem;
  font-size: var(--text-font-size);
  color: ${style.iconColor} !important;
`;

const renderCreditorCard = (creditor, keyPrefix, i) => (creditor.svgLogo || creditor.logo ? (
  <CreditorCard
    backgroundColor={style.backgroundColor}
    key={`${keyPrefix}-${i}`}
  >
    <ImageContainer
      imageWidth={style.imageWidth}
      maxHeight={style.maxHeight}
      imageObject={creditor.logo}
    />
  </CreditorCard>
) : null);

const CreditorGridComponent = React.memo(({ creditors, keyPrefix, gridProps }) => (
  <CreditorGrid {...gridProps}>
    {creditors.map((creditor, i) => renderCreditorCard(creditor, keyPrefix, i))}
  </CreditorGrid>
));

const ExpandButtonComponent = React.memo(({
  id, onClick, icon, label,
}) => (
  <ExpandButton id={id} type="button" tabIndex={0} onClick={onClick}>
    <Icon icon={icon} />
    {label}
  </ExpandButton>
));

const remToPixels = (rem) => {
  if (typeof window !== 'undefined' && window.getComputedStyle) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }
  return rem;
};

const PureCreditorsSection = ({ creditors, mortgageCreditors, acf }) => {
  const { titlePosition, titleColor, titleLine } = acf.sectionSettings;
  const showMortgage = acf.mortgage;
  const [showAllCreditors, setShowAllCreditors] = useState(false);
  const [showAllMortgageCreditors, setShowAllMortgageCreditors] = useState(false);

  const isWidthLaptop = useMemo(() => remToPixels(devices.isLaptopWidth), []);

  useLayoutEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth < isWidthLaptop) {
      const dotsContainer = document.querySelector('.alice-carousel__dots');
      const dotsItems = dotsContainer?.querySelectorAll('.alice-carousel__dots-item') || [];
      dotsItems.forEach((item, index) => {
        if (index >= 3) {
          // Use Object.assign to avoid direct assignment
          Object.assign(item.style, { display: 'none' });
        }
      });
    }
  }, [isWidthLaptop]);

  const scrollToElement = useCallback((elementId) => {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100); // Slight delay to ensure DOM has updated
  }, []);

  const toggleShowAllCreditors = useCallback(() => {
    setShowAllCreditors((prev) => {
      scrollToElement('creditor-card');
      return !prev;
    });
  }, [scrollToElement]);

  const toggleShowAllMortgageCreditors = useCallback(() => {
    setShowAllMortgageCreditors((prev) => {
      scrollToElement('mortgage-creditor-card');
      return !prev;
    });
  }, [scrollToElement]);

  const gridProps = useMemo(() => ({
    gridColumnMobile: style.gridColumnMobile,
    creditorsColumnsGrid: style.creditorsColumnsGrid,
  }), []);

  const getVisibleCreditors = (creditorList, showAll) => {
    const creditorsAcf = creditorList?.creditorsAcf || [];
    return showAll ? creditorsAcf : creditorsAcf.slice(0, style.creditorsColumns);
  };

  const getSectionContent = (creditorList, keyPrefix, gridProperties, showAll, toggleShowAll) => {
    const visibleCreditors = getVisibleCreditors(creditorList, showAll);

    return (
      <>
        <CreditorGridComponent
          creditors={visibleCreditors}
          keyPrefix={keyPrefix}
          gridProps={{
            ...gridProperties,
            numberOfCreditors: visibleCreditors.length,
            id: `${keyPrefix}-card`,
          }}
        />
        {creditorList?.creditorsAcf?.length > style.creditorsColumns && (
          <ExpandButtonComponent
            id={`${keyPrefix}Expand`}
            onClick={toggleShowAll}
            icon={showAll ? ['fal', 'chevron-up'] : ['fal', 'chevron-down']}
            label={showAll ? creditorList.collapseButtonLabel : creditorList.expandButtonLabel}
          />
        )}
      </>
    );
  };

  const sectionContent = getSectionContent(
    creditors, // No conflict here
    'creditor',
    gridProps,
    showAllCreditors,
    toggleShowAllCreditors,
  );

  const mortgageSectionContent = getSectionContent(
    mortgageCreditors, // No conflict here
    'mortgageCreditor',
    gridProps,
    showAllMortgageCreditors,
    toggleShowAllMortgageCreditors,
  );

  return (
    <SectionWrapper settings={acf.sectionSettings}>
      {acf.title && <Title position={titlePosition} color={titleColor}>{acf.title}</Title>}
      {acf.subtitle && <Subtitle>{acf.subtitle}</Subtitle>}
      {titleLine && <TitleLine />}
      {showMortgage ? mortgageSectionContent : sectionContent}
    </SectionWrapper>
  );
};

const CreditorsSection = React.memo((props) => {
  const { optionCreditors, optionMortgageCreditors } = useOptions();
  return (
    <PureCreditorsSection
      {...props}
      creditors={optionCreditors}
      mortgageCreditors={optionMortgageCreditors}
    />
  );
});

PureCreditorsSection.propTypes = {
  acf: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    sectionSettings: PropTypes.shape({
      titleColor: PropTypes.string,
      titleLine: PropTypes.bool,
      titlePosition: PropTypes.string,
    }).isRequired,
    mortgage: PropTypes.bool,
  }).isRequired,
  creditors: PropTypes.shape({
    creditorsAcf: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        logo: PropTypes.shape({
          altText: PropTypes.string,
          image: ImageProp,
          svgImage: SVGImageProp,
        }),
      }),
    ),
    collapseButtonLabel: PropTypes.string,
    expandButtonLabel: PropTypes.string,
  }).isRequired,
  mortgageCreditors: PropTypes.shape({
    mortgageCreditorsAcf: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        logo: PropTypes.shape({
          altText: PropTypes.string,
          image: ImageProp,
          svgImage: SVGImageProp,
        }),
      }),
    ),
    collapseButtonLabel: PropTypes.string,
    expandButtonLabel: PropTypes.string,
  }).isRequired,
};

CreditorsSection.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
};

CreditorsSection.defaultProps = {
  icon: null,
};

CreditorGridComponent.propTypes = {
  creditors: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      logo: PropTypes.shape({
        altText: PropTypes.string,
        image: ImageProp,
        svgImage: SVGImageProp,
      }),
    }),
  ).isRequired,
  keyPrefix: PropTypes.string.isRequired,
  gridProps: PropTypes.shape({}).isRequired, // Updated to be more specific
};

ExpandButtonComponent.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
};

ExpandButtonComponent.defaultProps = {
  icon: null,
};

export { PureCreditorsSection };
export default React.memo(CreditorsSection);
